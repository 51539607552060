import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";

import Test from "../other/test";
import CompareItems from "../components/AC-Compare/CompareItems";

const compareItems = () => {
  return (
    <Layout>
      <SEO title="Compare Items" />
      <CompareItems />
    </Layout>
  );
};

export default compareItems;
